@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Mona+Sans:ital,wght@0,200..900;1,200..900&family=Noto+Serif+SC:wght@200;300;400;500;600;700;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html{
  font-size: calc(10px + (24 - 6) * ((100vw - 440px) / 800));
  /* font-size: calc(0px + 1 * ((100vw - 440px)/1072)); */

  --rem-divider: 34.12;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
