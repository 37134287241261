.global-container {
    overflow: hidden;
    width: 100vw; 
    height: 100vh;
    position: relative;
  }
  
  .screen-wrapper {
    display: flex;
    width: 200%;
    transition: transform 0.6s ease-in-out;
  }
  
  .screen {
    width: 100vw;
    height: 100%; 
    flex-shrink: 0;
  }
  
  .dashboard-screen {
    background-color: white;
  }
  
  .blog-screen {
    background-color: #f5f5f5; 
  }
  