#references {
    width: 100%;
    background-color: #fff;
    border-bottom: calc(1.2rem/var(--rem-divider)) solid #0000ED;
}

.reference-container {
    width: 100%;
    height: 100%;
    padding: calc(60rem/var(--rem-divider));
    background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
    background-image: radial-gradient(circle, #ccc calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
}

.reference-grids {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.three-dup{
    border: calc(1rem/var(--rem-divider)) solid #CCCCCC;
}

.ref-grid {
    width: calc(100%/3);
    aspect-ratio: 1;
    border: calc(0.5rem/var(--rem-divider)) solid #CCCCCC;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.ref-grid h3 {
    width: 100%;
    margin-top: calc(-7rem/var(--rem-divider));
    margin-left: calc(-2rem/var(--rem-divider));
    font-weight: 900;
    font-size: calc(48rem/var(--rem-divider));
    line-height: calc(48rem/var(--rem-divider));
    padding-right: calc(30rem/var(--rem-divider));
}

.refer-text {
    width: 100%;
    padding: calc(20rem/var(--rem-divider));
    font-weight: 400;
    font-size: calc(18rem/var(--rem-divider));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.refer-text p {
    line-height: calc(24rem/var(--rem-divider));
}

.refer-info {
    width: 100%;
    display: flex;
    gap: calc(14rem/var(--rem-divider));
    justify-content: flex-start;
    align-items: flex-start;
}
.refer-info img{
    width: calc(64rem/var(--rem-divider));
    height: calc(64rem/var(--rem-divider));
}
.refer-details{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.refer-details p{
    text-transform: capitalize;
}
.refer-details p:first-of-type{
    font-weight: 700;
    font-size: calc(16rem/var(--rem-divider));
}
.refer-details p:last-of-type{
    margin-top: calc(-2.5rem/var(--rem-divider));
    font-size: calc(16rem/var(--rem-divider));
    color: #6f6f6f;
}