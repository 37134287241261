.about-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
}

#about{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    padding: calc(140rem/var(--rem-divider)) calc(60rem/var(--rem-divider));
    background-color: #000000;
    gap: calc(120rem/var(--rem-divider));
}
.about-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about-content h2 {
    font-size: calc(48rem/var(--rem-divider));
    font-weight: 700;
    color: #ffffff;
}

.about-content p {
    font-size: calc(28rem/var(--rem-divider));
    width: calc(953 / 1392 * 100%);
    color: #ffffff;
    line-height: calc(38rem/var(--rem-divider));
}

.small-text{
    font-size: calc(20rem/var(--rem-divider));
    font-weight: 300;
}

.toolkit{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(6rem/var(--rem-divider));
}

.toolkit h3{
    font-size: calc(30rem/var(--rem-divider));
    width: 100%;
    font-weight: 700;
    color: #ffffff;
    border-bottom: calc(1rem/var(--rem-divider)) solid #5a5a5a;
    padding-bottom: calc(1rem/var(--rem-divider));
}

.tools-list{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(20rem/var(--rem-divider));
}

.tool-li{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(10rem/var(--rem-divider));
}
.tool-li h4{
    font-size: calc(24rem/var(--rem-divider));
    font-weight: 700;
    color: #ffffff;
}
.tool-li ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(2rem/var(--rem-divider));
    list-style: none;
    color: #ffffff;
    font-size: calc(20rem/var(--rem-divider));
    font-weight: 300;
}
.tool-li ul li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: calc(5rem/var(--rem-divider));
}
.tool-li ul img{
    width: calc(24rem/var(--rem-divider));
    height: calc(24rem/1var(--rem-divider));
}