* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    padding: calc(16rem/var(--rem-divider)) calc(30rem/var(--rem-divider));
    width: 100vw;
    border-bottom: 0.03rem solid #0000ED; 
    z-index: 1000;
}

.navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: calc(10rem/var(--rem-divider));
}

a {
    text-decoration: none;
}

.logo {
    height: calc(23.4rem/var(--rem-divider));
    width: calc(23.4rem/var(--rem-divider)); 
}

.navbar-left:hover .logo{
    animation: spin 3s linear infinite;
}
.navbar-left h1 {
    font-size: calc(18rem/var(--rem-divider));
    font-weight: 700;
    color: #0000ED;
}

.navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(24rem/var(--rem-divider));
}

.navbar-right a {
    font-size: calc(19rem/var(--rem-divider));
    font-weight: 700;
    color: #0000ED;
    text-decoration: none;
    cursor: pointer;
}

.navbar-right a:hover {
    text-decoration: underline;
}
