#contact {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vw * 770.4 / 1512) !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(60rem/var(--rem-divider));
    padding: calc(60rem/var(--rem-divider));
    padding-bottom: calc(33rem/var(--rem-divider));
}

.relocation-container {
    width: 100%;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.relocation-container img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
}
.relocation-text{
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.relocation-text p{
    font-weight: 800;
    font-size: calc(26rem/var(--rem-divider));
}
.relocation-text h4{
    margin-top: calc(0rem/var(--rem-divider));
    font-weight: 900;
    font-size: calc(52rem/var(--rem-divider));
}

.contact-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.contact-info h3 {
    font-size: calc(96rem/var(--rem-divider));
    font-weight: 900;
    line-height: calc(116rem/var(--rem-divider));
    margin-bottom: calc(-20rem/var(--rem-divider));
}

.contact-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: calc(12rem/var(--rem-divider));
}

.contact-buttons a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: calc(160rem/var(--rem-divider));
    width: calc(160rem/var(--rem-divider));
    padding: calc(8rem/var(--rem-divider));
    border: calc(1.2rem/var(--rem-divider)) solid #0000ED;
    background-color: #fff;
    text-decoration: none;
}

.contact-buttons a img {
    width: calc(33rem/var(--rem-divider));
    height: calc(33rem/var(--rem-divider));
}
.contact-buttons a img:first-of-type{
    display: block;
}
.contact-buttons a img:last-of-type{
    display: none;
}

.contact-buttons a p {
    width: 100%;
    text-align: left;
    font-size: calc(24rem/var(--rem-divider));
    line-height: calc(29rem/var(--rem-divider));
    margin-bottom: calc(-4rem/var(--rem-divider));
    color: #0000ED;
}

.contact-buttons a:hover {
    background-color: #0000ED;
}

.contact-buttons a:hover p {
    color: #fff;
}
.contact-buttons a:hover img:first-of-type{
    display: none;
}
.contact-buttons a:hover img:last-of-type{
    display: block;
}