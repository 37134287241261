#projects {
    width: 100%;
    padding: calc(80rem/var(--rem-divider)) calc(60rem/var(--rem-divider));
    padding-bottom: calc(140rem/var(--rem-divider));
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(50rem/var(--rem-divider));
}

.projects-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: calc(12rem/var(--rem-divider));
}

.projects-header h2 {
    font-size: calc(48rem/var(--rem-divider));
    font-weight: 700;
    color: #000;
}

.projects-header img {
    width: calc(30.11rem/var(--rem-divider));
    height: calc(44rem/var(--rem-divider));
}

#projects table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#projects table thead {
    width: 100%;
}

#projects thead tr {
    width: 100%;
    display: flex;
    align-items: flex-end;
    font-size: calc(18rem/var(--rem-divider));
    font-weight: 500;
    color: #8f8f8f;
}

tr th {
    text-align: left;
    padding-right: calc(6rem/var(--rem-divider));
}

tr th:nth-of-type(1), tr td:nth-of-type(1) {
    width: calc(326rem/var(--rem-divider));
}

tr th:nth-of-type(2), tr td:nth-of-type(2) {
    flex-grow: 1;
}

tr th:nth-of-type(3), tr td:nth-of-type(3) {
    width: calc(175rem/var(--rem-divider));
}

tr th:nth-of-type(4), tr td:nth-of-type(4) {
    width: calc(341rem/var(--rem-divider));
}

tr th:nth-of-type(5), tr td:nth-of-type(5) {
    width: calc(206rem/var(--rem-divider));
}

tbody {
    width: 100%;
}

tbody tr {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
tbody tr:hover{
    background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
    background-image: radial-gradient(circle, #ccc calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
}
tbody tr:has(td.preview):hover{
    background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
    background-image: radial-gradient(circle, rgb(0, 0, 237, 0.3) calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
}


tr td a {
    position: relative;
    width: 100%;
    height: 100%;
    color: #000000;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    text-decoration: none;
    padding-top: calc(24rem/var(--rem-divider));
    padding-right: calc(6rem/var(--rem-divider));
    font-size: calc(15rem/var(--rem-divider));
}
tr:has(td.preview) td a{
    color: #0000ED;
}
tr td a img{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(300rem/var(--rem-divider));
    height: calc(200rem/var(--rem-divider));
    object-fit: cover;
    display: none;
    z-index: 100;
    pointer-events: none;
}
tr:hover td a img{
    display: block;
}
tbody tr:first-of-type td a{
    padding-top: calc(18rem/var(--rem-divider));
}

tr td:first-of-type a {
    width: calc(326rem/var(--rem-divider));
    font-weight: 500;
    font-size: calc(22rem/var(--rem-divider));
}

tr td:last-of-type a{
    justify-content: center;
    text-transform: uppercase;
    padding-right: 0;
}
tr td:last-of-type a p{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: calc(1.2rem/var(--rem-divider)) solid #000000;
    border-left: calc(1.2rem/var(--rem-divider)) solid #000000;
    border-right: calc(1.2rem/var(--rem-divider)) solid #000000;
    background-color: #fff;
}
tr td.preview:last-of-type a p{
    border-top: calc(1.2rem/var(--rem-divider)) solid #0000ED;
    border-left: calc(1.2rem/var(--rem-divider)) solid #0000ED;
    border-right: calc(1.2rem/var(--rem-divider)) solid #0000ED;
    color: #0000ED;
}

tr td:not(:first-of-type) {
    display: flex;
    align-items: flex-end;
}

tr td:not(:last-of-type) {
    border-bottom: calc(1.2rem/var(--rem-divider)) solid #000000;
}
tr:has(td.preview) td:not(:last-of-type) {
    border-bottom: calc(1.2rem/var(--rem-divider)) solid #0000ED;
}

