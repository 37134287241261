#hero {
    /* position: fixed; */
    width: 100vw;
    aspect-ratio: 1512/770.4 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    z-index: 0;
    background-color: #fff;
}

.hero-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
    background-image: radial-gradient(circle, #ebebeb calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
}

.hero-left {
    height: 100%;
    flex-grow: 1;
    padding: calc(30rem/var(--rem-divider));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.hero-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(10rem/var(--rem-divider));
}

.hero-text p {
    font-size: calc(20rem/var(--rem-divider));
    font-weight: 700;
    white-space: nowrap;
}
.hero-text p:nth-of-type(2){
    margin-top: calc(-5rem/var(--rem-divider));
}
.highlight{
    color: #0000ED;
}

.big-text{
    display: flex;
    flex-direction: column;
    gap: calc(-5rem/var(--rem-divider));
    width: 100%;
}

.big-text img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    animation: fadeInMoveUp 0.5s ease-in-out forwards;
    /* clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);   */
}

@keyframes fadeInMoveUp {
    0% {
        opacity: 0;
        transform: translateY(20px);  /* Adjust this value as needed */
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}


/* @keyframes blinds {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); 
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);  
    }
} */

video {
    width: calc(578/1512*100%);
    height: 100%;
    object-fit: cover;
    object-position: right;
    border-left: calc(1.2rem/var(--rem-divider)) solid #0000ED; 
}

.hero-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.scroll-down-btn{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: calc(12rem/var(--rem-divider)); 
    font-size: calc(16rem/var(--rem-divider));
    color: #0000ED;
}

.arrow-square {
    position: relative;
    width: calc(40rem/var(--rem-divider));
    height: calc(40rem/var(--rem-divider));
    border: calc(1.5rem/var(--rem-divider)) solid #0000ED;
    border-radius: calc(5rem/var(--rem-divider));
    z-index: 0;
    overflow: hidden; 
}

.arrow-square img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(22rem / var(--rem-divider));
    height: calc(22rem / var(--rem-divider));
    
    /* Apply the animation */
    animation: arrowMove 2s ease-in-out infinite;
}

.scroll-down-btn:hover .arrow-square{
    background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
    background-image: radial-gradient(circle, #0000ED calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
}
/* .scroll-down-btn:hover .arrow-square img{
    filter: brightness(0) invert(1);
} */

/* Updated keyframes for continuous movement */
@keyframes arrowMove {
    0% {
        top: -100%; /* Start above the square */
    }
    50% {
        top: 50%; /* Move to center */
    }
    100% {
        top: 100%; /* Move below the square */
    }
}

