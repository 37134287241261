*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
.container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Edge, and Safari */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scroll-behavior: smooth;
}

.container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and newer Edge */
}

.loader-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: #ffffff;
}

.loaderball {
  height: calc(25rem / var(--rem-divider));
  width: calc(25rem / var(--rem-divider));
  background-color: #0000ee;
  border-radius: 50%;
  animation: bounce 1s ease-in-out infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) scale(1, 1);
  }
  50% {
    transform: translateY(-50px) scale(0.8, 1.2);
  }
  75% {
    transform: translateY(0) scale(1.2, 0.8);
  }
}


.scroller{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.scrollings{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  /* padding-top: calc(100vw * 770.4/1512);
  padding-bottom: 100vh;
  pointer-events: none; */
}

.footer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: calc(12rem/var(--rem-divider)) calc(60rem/var(--rem-divider));
  background-size: calc(6rem/var(--rem-divider)) calc(6rem/var(--rem-divider));
  background-image: radial-gradient(circle, #ebebeb calc(1rem/var(--rem-divider)), #cccccc00 calc(1rem/var(--rem-divider)));
  color: #0000ED;
  /* border-top: calc(1.2rem/var(--rem-divider)) solid #0000ED; */
}
.footer p{
  font-size: calc(14rem/var(--rem-divider));
  cursor: pointer;
}