.detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
}

.navbar-left h1,
.navbar-left p {
    font-size: calc(18rem/var(--rem-divider));
    font-weight: 700;
    color: #0000ED;
}

.detail-container {
    width: calc(100vw - 160rem/var(--rem-divider));
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

.detail-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(16rem/var(--rem-divider)) 0;
}

.detail-header h1 {
    font-size: calc(24rem/var(--rem-divider));
    font-weight: 700;
    color: #ffffff;
}

.detail-header button {
    background: none;
    border: none;
    cursor: pointer;
    height: calc(50rem/var(--rem-divider));
    width: calc(50rem/var(--rem-divider));
    color: #ffffff;
    font-size: calc(20rem/var(--rem-divider));
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #343434;
    border-radius: calc(8rem/var(--rem-divider));
    transition: background-color 0.2s;
}

.detail-header button:hover {
    background-color: #696969;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    display: block;
    padding: 0;
    margin: 0;
    background: none;
    overflow-x:hidden;
}

iframe::-webkit-scrollbar {
    display: none;
}

.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f9f9f9;
}

.pdf-document {
    width: 90%; /* Scale the PDF document */
    max-height: 80%;
    overflow: hidden;
}

.pdf-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 90%;
}

.pdf-controls button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.pdf-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pdf-controls p {
    margin: 0;
    font-size: 14px;
}
